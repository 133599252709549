export default {
  sortItems(key, order = 'ascending') {
    return (a, b) => {
      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
      // Sort undefined values
      if (varA === undefined || varB === undefined) {
        return !varA ? 1 : -1;
      }
      let comparison = 0;
      if (typeof varA === 'string' && typeof varB === 'string') {
        comparison = varA.localeCompare(varB);
      } else if (+varA > +varB) {
        // +varA and +varB handle null and zero values
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'descending' ? comparison * -1 : comparison;
    };
  },
};
