<template>
  <component :is="'el-table-column'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="passport-table-column">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
    <!-- <template v-slot="slotData">
      <div class="wrapper">
        <slot v-bind="slotData" />
      </div>
    </template> -->
  </component>
</template>

<script>
export default {
  name: 'PassportTableColumn',
  inheritAttrs: false,
  extends: 'el-table-column',
};
</script>

<style lang="scss" scoped>
.passport-table-column {
  //
}
</style>
