<template>
  <component :is="'el-table'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="passport-table" ref="elTable">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
export default {
  name: 'PassportTable',
  inheritAttrs: false,
  computed: {
    tableRef() {
      return this.$refs.elTable;
    },
  },
};
</script>

<style lang="scss" scoped>
$divider: #d1d1d1;
$bg-grey: #ebebeb;
// Tables
$table-header-background-color: $dark-neutral;
$table-header-text-color: $lightest-neutral;
$table-text-color: $dark-neutral;
$table-background-color: $lightest-neutral;
$table-border-color: $bg-grey;
$table-highlighted-background-color: $bg-grey;

$table-sub-header-background-color: $divider;
$table-sub-header-text-color: $dark-neutral;
$table-sub-background-color: #f5f5f5;
$table-sub-text-color: $dark-neutral;
.passport-table {
  background: none;

  &::before {
    height: 0;
  }

  .table_empty {
    margin-top: 20px;
  }
  ::v-deep {
    &.checks {
      th,
      td {
        &:first-of-type {
          text-align: center;
        }
      }
    }
    th {
      height: 40px !important;
      padding: 0 !important;
      font-size: 12px;
      font-weight: normal;
      color: #fff;
      text-transform: capitalize;
      &.el-table__cell {
        font-size: 18px;
        background-color: transparent;
        &.is-leaf {
          border-bottom: 1px solid $table-border-color;
        }
      }
      .caret-wrapper {
        position: absolute;
      }
      .sort-caret {
        position: absolute;
        width: 16px;
        height: 8px;
        background: url('~@/assets/icons/down_carat.svg?external') center center no-repeat;
        background-size: cover;
        border: 0;
        opacity: 0;
      }
      &.ascending {
        .sort-caret.ascending {
          top: 7px;
          opacity: 1;
          transform: rotate(180deg);
        }
      }
      &.descending {
        .sort-caret.descending {
          top: 7px;
          opacity: 1;
        }
      }
    }
    td {
      color: white;
      border-left: 0;

      &:first-child {
        &.el-table__cell {
          > .cell {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }
        }
      }
      &:last-child {
        &.el-table__cell {
          > .cell {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
      &.el-table__cell {
        height: 50px;
        padding: 5px 0 0 0;
        border-bottom: 0;
        &.el-table__expanded-cell {
          padding-left: 66px;
          background: inherit;
        }

        > .cell {
          display: flex;
          align-items: center;
          height: 100%;
          letter-spacing: -0.16px;
          background: rgba(white, 0.15);
        }
      }
      &.el-table__expand-column {
        .cell {
          height: 100%;
        }
      }
    }
    .ellipsis .cell {
      white-space: nowrap;
    }
    .cell {
      word-break: break-word;
    }
    .el-table__expand-icon {
      position: absolute;
      left: -50px;
      height: 100%;
      font-size: 12px;
      // display: none;

      .el-icon {
        margin: 0;
        margin-top: -1px;
        margin-left: 3em;
        color: white;
        transform: translate(-50%, -50%) rotateZ(90deg);
        @include bp-lg-laptop {
          margin-left: 2.65em;
        }
      }
    }
    .el-table__expand-icon--expanded {
      transform: rotateZ(0deg);

      > .el-icon {
        transform: translate(-50%, -50%) rotateZ(-90deg);
      }
    }
    .el-table__body {
      tr:hover {
        .el-table__cell {
          background-color: inherit;
        }
      }
    }
    tr {
      font-size: 13px;
      background-color: transparent;
      border-bottom: 0;
    }
    .action-row {
      display: flex;
      align-items: center;
      padding: 0 5px;

      .text--table-action {
        display: flex;
        align-items: center;
        padding: 5px;
        white-space: nowrap;
        // @include font-size(14, 500, none, 0.7, 18);
      }

      .g-divider {
        height: 25px;
        margin: 15px 12.5px;
      }
    }
  }
}
</style>
