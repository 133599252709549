<template>
  <component :is="'GPagination'" v-bind="{ ...$attrs, ...$props }" v-on="$listeners" class="passport-pagination">
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
  </component>
</template>

<script>
import { GPagination } from '@twentyfourg/grimoire';

export default {
  name: 'PassportPagination',
  inheritAttrs: false,
  extends: GPagination,
  components: {
    GPagination,
  },
};
</script>

<style lang="scss" scoped>
.passport-pagination {
  width: 100%;
  max-width: 450px;
  font-size: 14px;
  color: white;
  @include bp-sm-phone-landscape {
    width: 450px;
  }

  ::v-deep {
    .arrow {
      width: 12px;
      height: 100%;
      line-height: 30px;
    }

    button {
      padding: 5px;
      color: inherit;
      background: none;
      border: none;
    }
    .active {
      button {
        font-weight: bold;
      }
    }
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 50px;
      // margin: 0 15px;
      &.disabled {
        button {
          opacity: 0.25;
        }
      }
      &.li-prev {
        margin-right: auto;
      }
      &.li-next {
        margin-left: auto;
      }
    }
  }
}
</style>
